import classnames from 'classnames';
import React from 'react';
import { Grid as LoadingIndicator } from 'svg-loaders-react';

import styles from './styles.module.css';

const Loading = (props) => {
  const {
    className: classNameProp,
    ...otherProps
  } = props;

  const className = classnames(styles.root, classNameProp);

  return (
    <div className={className} {...otherProps}>
      <LoadingIndicator />
    </div>
  );
}

export default Loading;
